<template>
  <div>
    <h3>Billy Joel says&hellip;</h3>
    <h1>Man what are you doin' here?</h1>
    <h3>Error 404</h3>
    <h3>🎹</h3>
    <p style="color: #bbb; font-size: 0.8em;">{{ globals.endpoint }}</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    globals: Object
  }
}
</script>